import { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import { Button, Popconfirm, Tooltip, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import AppItem from 'components/AppItem';
import AppTotal from 'components/AppTotal';

import { createApplication, clearApplications } from 'store/applications';

import { RootState } from 'store';

import { App } from 'types/applications';

import css from './Applications.module.scss';

type IApplications = {
  applications: App[];
  createApplication: () => void;
  clearApplications: () => void;
};

const Applications: FC<IApplications> = ({ applications, createApplication, clearApplications }) => {
  useEffect(() => { if (applications.length === 0) createApplication(); });

  return (
    <div className={css.Applications}>
      <div className={css.Applications__column}>
        <div className={css.Applications__header}>
          <Typography.Title level={2} className='h2Fix'>Акты</Typography.Title>
          <Button onClick={() => { createApplication(); }} type='primary'>Добавить</Button>
          <Tooltip title='Очистить' placement="right">
            <Popconfirm title="Очистить все текущие карточки?" onConfirm={() => clearApplications()}>
              <Button icon={<DeleteOutlined />} danger />
            </Popconfirm>
          </Tooltip>
        </div>
        <div className={css.Applications__items}>
          {applications.map((app) => <AppItem key={app.id} appData={app} />)}
        </div>
      </div>
      <div className={css.Applications__column} style={{ width: '100%' }}>
        <div className={css.Applications__header}>
          <Typography.Title level={2} className='h2Fix'>Итоги</Typography.Title>
        </div>
        <AppTotal />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  applications: state.applications
});

export default connect(mapStateToProps, { createApplication, clearApplications })(Applications);
