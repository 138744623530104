import { FC } from 'react';

import pkg from '../../../package.json';

import css from './Footer.module.scss';

const Footer: FC = () => (
  <div className={css.Footer}>
    {`Lucky Calc v${pkg.version} by m1w_dev`}
    <div />
  </div>
);

export default Footer;
