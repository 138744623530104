/* eslint-disable no-continue */
import dayjs from 'dayjs';

const getLocalStores = (toExport = false) => {
  const stores = [];

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (!(key && key.includes('m1w_dev') && key.includes('luckycalc') && !key.includes('permanent'))) continue;

    const date = key.split(':')?.[0];
    if (!date) continue;

    const localData = localStorage.getItem(key);
    if (!localData) continue;

    try {
      const parsedData = JSON.parse(localData);

      stores.push({
        date: toExport ? date : dayjs(date),
        store: parsedData,
        key
      });
    } catch (err) {
      continue;
    }
  }

  return stores;
};

export default getLocalStores;
