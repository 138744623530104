import dayjs from 'dayjs';

import { store } from 'store';

import { changeTheme } from 'store/theme';
import getLocalStores from './getLocalStores';

const getCurrentTheme = () => {
  const archive = getLocalStores(true);

  const notToday = archive.filter((item) => !(dayjs(item.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')));

  notToday.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix());

  const latest = notToday?.[0]?.store || 'light';

  if (['light', 'dark', '"light"', '"dark"'].includes(latest.theme)) {
    store.dispatch(changeTheme(['dark', '"dark"'].includes(latest.theme)));
  }
};

export default getCurrentTheme;
