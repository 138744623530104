/* eslint-disable no-continue */
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

const setReloadNotification = (text: string, type: 'success' | 'warning' | 'error' | 'info' | 'default' = 'info') => {
  const recordName = `${dayjs().unix()}:m1w_dev:reload_notification:${v4()}`;

  localStorage.setItem(recordName, JSON.stringify({ type, text }));
};

const getReloadNotifications = () => {
  const notifications = [];

  for (let i = 0; i < localStorage.length; i += 1) {
    const key = localStorage.key(i);
    if (!(key && key.includes('m1w_dev') && key.includes('reload_notification'))) continue;

    const localData = localStorage.getItem(key);
    if (!localData) continue;

    try {
      const parsedData = JSON.parse(localData);

      if (
        parsedData
        && typeof parsedData === 'object'
        && Object.hasOwn(parsedData, 'type')
        && Object.hasOwn(parsedData, 'text')
        && typeof parsedData.type === 'string'
        && typeof parsedData.text === 'string'
        && parsedData.text !== ''
      ) {
        notifications.push({ type: parsedData.type, text: parsedData.text, key });
      }
    } catch (err) {
      continue;
    }
  }

  notifications.forEach(({ type, text, key }) => {
    localStorage.removeItem(key);
    switch (type) {
      case 'info':
        toast.info(text);
        break;
      case 'success':
        toast.success(text);
        break;
      case 'warning':
        toast.warning(text);
        break;
      case 'error':
        toast.error(text);
        break;
      default:
        toast(text);
    }
  });
};

export default { set: setReloadNotification, get: getReloadNotifications };
