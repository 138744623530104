import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Menu } from 'antd';

import css from './Header.module.scss';
import menu from './navigationItems';

const Header: FC = () => {
  const [currentTabs, setTabs] = useState(['dashboard']);

  const location = useLocation();

  useEffect(() => {
    const entity = location.pathname.split('/')[1];
    const action = location.pathname.split('/')[2];

    const newTabs = [entity || 'calculator'];

    if (action) {
      newTabs.push(`${entity}:${action}`);
    } else {
      newTabs.push(`${entity}:all`);
    }

    setTabs(newTabs);
  }, [location]);

  return (
    <div className={css.Header}>
      <Menu items={menu} selectedKeys={currentTabs} mode="horizontal" className={css.Header__menu} />
    </div>
  );
};

export default Header;
