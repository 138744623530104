import { FC } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { Button, Popover, Typography, Upload } from 'antd';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';

import UsersTable from 'components/UsersTable';

import download from 'services/download';
import importValidator from 'services/inportValidator';

import { createDoctor, replaceDoctors } from 'store/doctors';

import { RootState } from 'store';

import type { UploadProps } from 'antd';
import { Doctor } from 'types/doctors';

import css from './Users.module.scss';

type IUsers = {
  doctors: Doctor[];
  createDoctor: () => void;
  replaceDoctors: (data: Doctor[]) => void;
};

const Users:FC<IUsers> = ({ doctors, createDoctor, replaceDoctors }) => {
  const uploadProps: UploadProps = {
    multiple: false,
    accept: '.json',
    showUploadList: false,
    customRequest: ({ file }) => {
      const reader = new FileReader();

      reader.onload = async ({ target }) => {
        const data = target?.result;
        try {
          const parsedData = JSON.parse(data as string);
          const checked = importValidator.doctors(parsedData);

          replaceDoctors(checked);
          toast.success('Импорт выполнен успешно');
        } catch (err) {
          toast.error('Произошла ошибка при обработке файла');
        }
      };
      reader.readAsText(file as Blob);
    },
  };

  return (
    <div className={css.Users}>
      <div className={css.Users__header}>
        <Typography.Title level={2} className='h2Fix'>Сотрудники</Typography.Title>
        <Button onClick={() => { createDoctor(); }} type='primary'>Добавить</Button>
        <Popover
          title="Выгрузить список сотрудников"
          content="Для переноса на другое устройство или восстановления в будущем."
          placement='bottom'
        >
          <Button
            onClick={() => { download('luckyDoctors', JSON.stringify(doctors)); }}
            type='dashed'
            icon={<CloudDownloadOutlined />}
          />
        </Popover>
        <Upload {...uploadProps}>
          <Popover
            title="Импортировать список сотрудников"
            content="Это действие очистит текущий список без возможности восстановления."
            placement='bottom'
          >
            <Button type='dashed' danger icon={<CloudUploadOutlined />} />
          </Popover>
        </Upload>
      </div>
      <UsersTable />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({ doctors: state.doctors });

export default connect(mapStateToProps, { createDoctor, replaceDoctors })(Users);
