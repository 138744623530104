const numParser = (imput: string | undefined) => {
  if (!imput) return 0;

  const cleared = (imput.match(/[0-9\s.,]*/g) || [])
    .map((i) => i.trim())
    .join('')
    .replace(/[\s,]/g, '.');

  const last = cleared.lastIndexOf('.');

  const res = cleared.split('').map((letter, i) => {
    if (letter !== '.') return letter;

    return (i === last) ? '.' : '';
  }).join('');

  return Number.isNaN(Number(res)) ? 0 : Number(res);
};

export default numParser;
