import { FC, useState } from 'react';
import dayjs from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';

import StatisticsDetails from 'components/StatisticsDetails';

import { ColumnsType } from 'antd/es/table';
import { StatisticsDay } from 'types/statistics';

import css from './StatisticsTable.module.scss';

type IStatisticsTable = {
  tableData: StatisticsDay[]
};

const StatisticsTable: FC<IStatisticsTable> = ({ tableData }) => {
  const [dayInfo, setDayInfo] = useState(null as StatisticsDay | null);

  const columns: ColumnsType<StatisticsDay> = [
    {
      title: 'Дата и время',
      key: 'date',
      dataIndex: 'date',
      render: (val) => (
        <>
          {dayjs(val).format('DD.MM.YYYY')}
          <br />
          {dayjs(val).format('HH:mm')}
        </>
      ),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Приход',
      key: 'total',
      dataIndex: 'total',
      render: (val) => val,
    },
    {
      title: 'Расходники',
      key: 'med',
      dataIndex: 'med',
      render: (val) => val,
    },
    {
      title: 'Лаба',
      key: 'lab',
      dataIndex: 'lab',
      render: (val) => val,
    },
    {
      title: 'Прочие расх.',
      key: 'common',
      dataIndex: 'common',
      render: (val) => val,
    },
    {
      title: 'Чистые',
      key: 'clear',
      dataIndex: 'clear',
      render: (val) => val,
    },
    {
      title: 'ЗП',
      key: 'toUsers',
      dataIndex: 'toUsers',
      render: (val, record) => (
        <>
          {val}
          <hr className={css.StatisticsTable__userInfo} />
          {Object.entries(record.byUsers).map((usr) => (
            <div className={css.StatisticsTable__userInfo} key={usr[0]}>
              <span>
                {record.doctors.find((doc) => doc.id === +usr[0])?.name || 'Не найден :(' }
              </span>
              <span><b>{usr[1]}</b></span>
            </div>
          ))}
        </>
      ),
    },
    {
      title: 'Доход',
      key: 'clinicProfit',
      dataIndex: 'clinicProfit',
      render: (val) => val,
    },
    {
      title: '',
      key: 'viewAction',
      render: (__, record) => (
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={() => setDayInfo(record)}
            icon={<EyeOutlined />}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <StatisticsDetails
        dayInfo={dayInfo}
        setDayInfo={setDayInfo}
      />
      <Table
        dataSource={tableData.map((data) => ({ ...data, key: data.date }))}
        columns={columns}
        className={css.StatisticsTable}
      />
    </>
  );
};

export default StatisticsTable;
