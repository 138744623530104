import { createSlice } from '@reduxjs/toolkit';

import { Doctor } from 'types/doctors';

const initialState: Doctor[] = [];

const doctors = createSlice({
  name: 'doctors',
  initialState,
  reducers: {
    createDoctor: (store) => [...store,
      { id: store.length + 1, name: `Сотрудник ${store.length + 1}`, defaultPercentage: 0, deleted: false }
    ],
    importDoctor: (store, { payload }) => [...store,
      { id: payload.id, defaultPercentage: payload.defaultPercentage, name: payload.name, deleted: payload.deleted }
    ],
    updateDoctor: (store, action) => {
      const index = store.findIndex((item) => action.payload.id === item.id);
      const current = store.find((item) => item.id === action.payload.id);

      if (current === undefined) return store;

      const deleted = action.payload.deleted && typeof action.payload.deleted === 'boolean' ? action.payload.deleted : false;

      return [...store.slice(0, index),
        { ...current, name: action.payload.name, defaultPercentage: action.payload.defaultPercentage, deleted },
        ...store.slice(index + 1)];
    },
    deleteDoctor: (store, action) => {
      const index = store.findIndex((item) => action.payload === item.id);
      const current = store.find((item) => item.id === action.payload);

      if (current === undefined) return store;

      return [...store.slice(0, index),
        { ...current, deleted: true },
        ...store.slice(index + 1)];
    },
    replaceDoctors: (__, action) => action.payload,
  },
});

export const { createDoctor, updateDoctor, deleteDoctor, replaceDoctors, importDoctor } = doctors.actions;

export default doctors.reducer;
