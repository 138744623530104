import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {};

const errors = createSlice({
  name: 'errors',
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        if (action.error.message === 'Failed to fetch') {
          toast.error('Ошибка при загрузке данных');
        } else {
          toast.error(action.error.message);
        }

        return { ...state };
      }
    );
  },
});

export default errors.reducer;
