import { createSlice } from '@reduxjs/toolkit';

const common = createSlice({
  name: 'common',
  initialState: 0,
  reducers: {
    setCommon: (__, { payload }) => (Number.isNaN(payload) ? 0 : payload),
  },
});

export const { setCommon } = common.actions;

export default common.reducer;
