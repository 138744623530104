import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const download = (filename: string, text: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', `${filename}.${dayjs().format('DD-MM-YYYY')}.json`);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);

  toast.info(`Загрузка файла "${filename}" началась`);
};

export default download;
