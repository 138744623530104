import md5 from 'md5';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DatePicker, Input, Table } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { ColumnsType } from 'antd/es/table';

import { formatString } from './utils';

import css from './StatisticsData.module.scss';

export type StatisticsDataProps = {
  stat: {
    total: number,
    lab: number,
    med: number,
    clear: number,
    toUsers: number,
    clinicProfit: number,
    byUsers: { [key: string]: number }
  },
  dates: [ dayjs.Dayjs, dayjs.Dayjs ] | null,
  setDates: React.Dispatch<React.SetStateAction<[ dayjs.Dayjs, dayjs.Dayjs ] | null>>
};

export const StatisticsData = ({
  stat, dates, setDates
}: StatisticsDataProps) => {
  const envPass = process.env.REACT_APP_STAT_PASSWORD;

  const [pass, setPass] = useState('');

  const tabStatData = [
    { key: 1, title: 'Приход', value: stat.total },
    { key: 2, title: 'Лаборатория', value: stat.lab },
    { key: 3, title: 'Расходники и лекарства', value: stat.med },
    { key: 4, title: 'Чистый приход', value: stat.clear },
    { key: 5, title: 'ЗП сотрудникам', value: stat.toUsers },
    { key: 6, title: 'Прибыль клиники', value: stat.clinicProfit },
  ];

  const statColumns : ColumnsType<{ title: string, value: number }> = [
    {
      title: 'Значение',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Сумма',
      key: 'value',
      dataIndex: 'value',
      render: (val) => formatString(Math.round(val)),
      align: 'right',
    },

  ];

  const tabUsersStatsData = Object.entries(stat.byUsers).map(([doctorName, value]) => ({
    key: doctorName, doctorName, value,
  }));

  const doctorsColumns: ColumnsType<{ doctorName: string, value: number }> = [
    {
      title: 'ФИО',
      key: 'doctorName',
      dataIndex: 'doctorName',
    },
    {
      title: 'ЗП',
      key: 'value',
      dataIndex: 'value',
      sortDirections: ['ascend', 'descend'],
      sorter: (v1, v2) => v1.value - v2.value,
      render: (val) => formatString(Math.round(val)),
      align: 'right',
    }
  ];

  return (
    <div className={css.StatisticsData}>
      <div className={css.StatisticsData__tables}>
        <div className={css.StatisticsData__tables}>
          <DatePicker.RangePicker
            value={dates}
            defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
            onChange={(dates) => setDates(dates as [ dayjs.Dayjs, dayjs.Dayjs ])}
            format="DD-MM-YYYY"
            className={css.StatisticsData__shadow}
          />
          {envPass && (
          <Input.Password
            className={css.StatisticsData__shadow}
            value={pass}
            onChange={(event) => setPass(event.target.value || '')}
            allowClear
            placeholder='Для отображения статистики введите пароль'
            autoComplete='off'
            // eslint-disable-next-line react/no-unstable-nested-components
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
          )}
        </div>
        <div>{' '}</div>

        {(envPass && md5(pass) === md5(envPass)) && (
        <>
          <Table
            key='statTable'
            columns={statColumns}
            dataSource={tabStatData}
            pagination={false}
            className={css.StatisticsData__shadow}
          />

          <Table
            key='byUsersTable'
            columns={doctorsColumns}
            dataSource={tabUsersStatsData}
            pagination={false}
            className={css.StatisticsData__shadow}
          />
        </>
        )}
      </div>
    </div>
  );
};
