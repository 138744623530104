import { FC, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button, Layout, Result } from 'antd';

import Header from 'views/Header';
import Applications from 'views/Applications';
import Statistics from 'views/Statistics';
import Users from 'views/Users';
import Data from 'views/Data';
import Footer from 'views/Footer';

import reloadNotifications from 'services/reloadNotifications';
import getLatestDoctors from 'services/getLatestDoctors';
import getCurrentTheme from 'services/getCurrentTheme';

import css from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  useEffect(() => {
    reloadNotifications.get();
    getLatestDoctors();
    getCurrentTheme();
  }, []);

  return (
    <Layout className={css.App}>
      <ToastContainer position="top-right" draggable={false} pauseOnHover theme="light" style={{ whiteSpace: 'pre-line' }} />
      <Header />
      <div className={css.App__content}>
        <Routes>
          <Route path='/' element={<Applications />} />
          <Route path='/calculator' element={<Applications />} />
          <Route path='/history' element={<Statistics />} />
          <Route path='/users' element={<Users />} />
          <Route path='/data' element={<Data />} />
          <Route
            path='*'
            element={(
              <Result
                status="404"
                title="404"
                subTitle="Похоже, такой страницы не существует"
                extra={<Link to="/"><Button type="primary">На главную</Button></Link>}
              />
          )}
          />
        </Routes>
      </div>
      <Footer />
    </Layout>
  );
};

export default App;
