import { FC } from 'react';
import { connect } from 'react-redux';

import { Button, InputNumber, Select, Tooltip } from 'antd';
import { MehOutlined, PercentageOutlined, UserDeleteOutlined, UserOutlined } from '@ant-design/icons';

import numParser from 'services/numParser';

import { deleteExecutor, updateExecutor } from 'store/applications';

import { RootState } from 'store';

import { Doctor } from 'types/doctors';
import { App, Executor } from 'types/applications';

import css from './DoctorItem.module.scss';

type IDoctorItem = {
  doctors: Doctor[];
  appData: App;
  executorData: Executor;
  updateExecutor: (data: { app: App, executor: Executor }) => void;
  deleteExecutor: (data: { app: App, executor: Executor }) => void;
  viewOnly?: boolean;
};

const inputStyle = { width: '125px' };

const DoctorItem: FC<IDoctorItem> = ({ doctors, appData, executorData, updateExecutor, deleteExecutor, viewOnly }) => (
  <div className={css.DoctorItem}>
    <Select
      options={doctors.map((item) => (
        { value: item.id, label: item.name, item }))
        .filter((item) => item.value === executorData.userId || !appData.executors.map((i) => i.userId).includes(item.value))
        .sort((a, b) => {
          const textA = a.label.toUpperCase();
          const textB = b.label.toUpperCase();
          if (textA < textB) return -1;
          if (textA > textB) return 1;

          return 0;
        })}
      style={{ width: '260px' }}
      onChange={(userId, option) => {
        if (Array.isArray(option)) return;
        updateExecutor(
          { app: appData, executor: { ...executorData, userId, currentPercentage: option.item.defaultPercentage } }
        );
      }}
      suffixIcon={<UserOutlined />}
      defaultValue={executorData?.userId}
      showSearch
      filterOption={(input, option) => {
        if (!option) return false;

        return option?.label.toUpperCase().includes(input.toUpperCase());
      }}
      disabled={viewOnly}
    />
    <InputNumber
      value={executorData.currentPercentage}
      addonAfter={<PercentageOutlined />}
      onChange={(currentPercentage) => {
        updateExecutor(
          { app: appData, executor: { ...executorData, currentPercentage: currentPercentage || 0 } }
        );
      }}
      style={inputStyle}
      disabled={!executorData.userId}
      parser={numParser}
      readOnly={viewOnly}
    />
    <InputNumber
      readOnly
      value={Math.round(executorData.currentPercentage * (appData.total - appData.med - appData.lab)) / 100}
      placeholder='Доход'
      addonAfter={<MehOutlined />}
      style={inputStyle}
      step={0.01}
      parser={numParser}
    />

    <Tooltip title="Удалить сотрудника" placement='left'>
      <Button
        danger
        size='middle'
        icon={<UserDeleteOutlined />}
        style={{ flexShrink: 0 }}
        onClick={() => deleteExecutor({ app: appData, executor: executorData })}
        disabled={viewOnly}
      />
    </Tooltip>

  </div>
);

const mapStateToProps = (state: RootState) => ({
  doctors: state.doctors.filter((doc) => !doc.deleted),
});

export default connect(mapStateToProps, { updateExecutor, deleteExecutor })(DoctorItem);
