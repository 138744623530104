import { FC, ReactNode } from 'react';

import { Button, Popconfirm, Popover } from 'antd';

import css from './DataButton.module.scss';

type IButtonTemplate = {
  icon: ReactNode;
  title: string;

  action: () => void;
  type?: 'primary' | 'dashed' | 'default';
  danger? : boolean,
  ghost? : boolean,
};

type IDataButton = {
  popoverTitle?: string | ReactNode;
  popoverContent?: string | ReactNode;
  confirmTitle?: string | ReactNode;
  confirmContent?: string | ReactNode;
  confirmAction?: () => void;
} & IButtonTemplate;

const DataButton: FC<IDataButton> = (
  { popoverTitle, popoverContent, confirmTitle, confirmContent, confirmAction, ...buttonProps }
) => {
  const { icon, title, action, type, danger, ghost } = buttonProps;

  const buttonTemplate = (
    <Button
      className={css.DataButton}
      onClick={() => action()}
      type={type}
      danger={danger}
      ghost={ghost}
    >
      <div className={css.DataButton__icon}>
        {icon}
      </div>
      <div className={css.DataButton__title}>
        {title}
      </div>
    </Button>
  );

  if (confirmAction && confirmTitle) {
    return (
      <Popconfirm
        title={confirmTitle}
        description={confirmContent}
        onConfirm={confirmAction}
        placement='bottom'
      >
        {buttonTemplate}
      </Popconfirm>
    );
  }

  if (popoverTitle) {
    return (
      <Popover title={popoverTitle} content={popoverContent} placement='bottom'>
        {buttonTemplate}
      </Popover>
    );
  }

  return buttonTemplate;
};

export default DataButton;
