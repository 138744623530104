import { FC } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import { Typography, Upload, UploadProps } from 'antd';
import { CloudDownloadOutlined, CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';

import DataButton from 'components/DataButton';

import download from 'services/download';
import importValidator from 'services/inportValidator';
import getLocalStores from 'services/getLocalStores';
import reloadNotifications from 'services/reloadNotifications';

import { replaceDoctors } from 'store/doctors';
import { setCommon } from 'store/common';
import { replaceApplications } from 'store/applications';

import { Doctor } from 'types/doctors';
import { App } from 'types/applications';

import css from './Data.module.scss';

type IData = {
  replaceDoctors: (doctors: Doctor[]) => void;
  setCommon: (common: number) => void;
  replaceApplications: (appliactions: App[]) => void;
};

const Data:FC<IData> = ({ replaceApplications, replaceDoctors, setCommon }) => {
  const uploadProps: UploadProps = {
    multiple: false,
    accept: '.json',
    showUploadList: false,
    customRequest: ({ file }) => {
      const reader = new FileReader();

      reader.onload = async ({ target }) => {
        const data = target?.result;
        try {
          const parsedData = JSON.parse(data as string);

          const checked = importValidator.globalImport(parsedData);

          localStorage.clear();

          checked.forEach((record) => {
            localStorage.setItem(record.key, JSON.stringify(record.store));
            if (record.date === dayjs().format('YYYY.MM.DD')) {
              replaceDoctors(record.store.doctors);
              replaceApplications(record.store.applications);
              setCommon(record.store.common);
            }
          });

          reloadNotifications.set('Импорт выполнен успешно!', 'success');
          window.location.reload();
        } catch (err) {
          toast.error('Произошла ошибка при обработке файла');
        }
      };
      reader.readAsText(file as Blob);
    },
  };

  return (
    <div className={css.Data}>
      <div className={css.Data__header}>
        <Typography.Title level={2} className='h2Fix'>Управление данными приложения</Typography.Title>
      </div>
      <div className={css.Data__buttons}>
        <DataButton
          title='Экспортировать'
          icon={<CloudDownloadOutlined />}
          popoverTitle="Сохранить данные приложения"
          popoverContent="Вы можете сохранить данные приложения на ПК для переноса или восстановления"
          action={() => download('luckyData', JSON.stringify(getLocalStores(true)))}
          type='primary'
        />
        <Upload {...uploadProps}>
          <DataButton
            title='Импортировать'
            icon={<CloudUploadOutlined />}
            popoverTitle="Восстановить данные из файла"
            popoverContent="Все текущие данные приложения будут удалены без возможности восстановления"
            action={() => { }}
            danger
          />
        </Upload>

        <DataButton
          title='Очистить'
          icon={<DeleteOutlined />}
          popoverTitle="Удалить все данные приложения"
          popoverContent="Все данные будут удалены. Это действие невозможно отменить!"
          action={() => { }}
          type='primary'
          danger
          confirmTitle="Подтвердить удаление?"
          confirmContent={(
            <>
              Будут удалены ВСЕ данные приложения. Убедитесь, что сохранили всё необходимое!
              <br />
              <b>Это действие невозможно отменить!</b>
            </>
          )}
          confirmAction={() => {
            localStorage.clear();
            reloadNotifications.set('Все данные приложения успешно очищены!');
            window.location.reload();
          }}
        />

      </div>
    </div>
  );
};

export default connect(null, { replaceDoctors, replaceApplications, setCommon })(Data);
