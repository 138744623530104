import { createSlice } from '@reduxjs/toolkit';

const theme = createSlice({
  name: 'theme',
  initialState: 'light',
  reducers: {
    changeTheme: (store, { payload }) => (payload ? 'dark' : 'light'),
  },
});

export const { changeTheme } = theme.actions;

export default theme.reducer;
