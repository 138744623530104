import { FC } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, connect } from 'react-redux';
import { ConfigProvider, theme } from 'antd';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import ErrorBoundary from 'views/Error';

import 'dayjs/locale/ru';
import locale from 'antd/locale/ru_RU';

import { store, persistor, RootState } from './store';

import 'normalize.css';
import './index.scss';

import App from './App';

const lightTheme = {
  token: {
    colorPrimary: '#3fad4f',
    colorInfo: '#3fad4f',
    colorLink: '#399661',
  },
  algorithm: [theme.defaultAlgorithm]
};

const darkTheme = {
  token: {
    colorPrimary: '#3fad4f',
    colorInfo: '#3fad4f',
    colorLink: '#399661',
    colorBgBase: '#262626',
  },
  algorithm: [theme.darkAlgorithm]
};

const customTheme = {
  dark: darkTheme,
  light: lightTheme
};

type ICustomConfigProvider = {
  currentTheme: 'dark' | 'light'
};

const CustomConfigProvider: FC<ICustomConfigProvider> = ({ children, currentTheme }) => (
  <ConfigProvider
    locale={locale}
    theme={customTheme[currentTheme]}
  >
    {children}
  </ConfigProvider>
);

const ConnectedCustomConfigProvider = connect((store: RootState) => (
  { currentTheme: store.theme as ICustomConfigProvider['currentTheme'] }
))(CustomConfigProvider);

const root = createRoot(document.getElementById('lucky_calc') as Element);

root.render(

  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedCustomConfigProvider>
        <ErrorBoundary>
          <HashRouter>
            <App />
          </HashRouter>
        </ErrorBoundary>
      </ConnectedCustomConfigProvider>
    </PersistGate>
  </Provider>
);
