import { FC } from 'react';
import { connect } from 'react-redux';

import { Button, Card, InputNumber, Popconfirm, Tooltip } from 'antd';
import {
  DeleteOutlined,
  DollarOutlined,
  ExperimentOutlined,
  MedicineBoxOutlined,
  MoneyCollectOutlined,
  UserAddOutlined
} from '@ant-design/icons';

import DoctorItem from 'components/DoctorItem';

import numParser from 'services/numParser';

import { createExecutor, updateApplication, deleteApplication } from 'store/applications';

import { RootState } from 'store';

import { App } from 'types/applications';
import { Doctor } from 'types/doctors';

import css from './AppItem.module.scss';

type IAppItem = {
  appData: App;
  doctors: Doctor[];
  updateApplication: (app: App) => void;
  deleteApplication: (app: App) => void;
  createExecutor: (app: App) => void;
  viewOnly?: boolean;
};

const inputStyle = { width: '125px' };

const AppItem: FC<IAppItem> = ({ appData, doctors, updateApplication, deleteApplication, createExecutor, viewOnly = false }) => {
  const totalProfit = appData.total - appData.med - appData.lab;
  const usersProfit =
  Math.round(appData.executors.reduce((sum, item) => sum + Math.round(item.currentPercentage * (totalProfit)) / 100, 0)
     * 100) / 100;
  const clinicProfit = Math.round((totalProfit - usersProfit) * 100) / 100;

  return (
    <Card
      className={css.AppItem}
      title={(
        <div className={css.AppItem__header}>
          <div className={css.AppItem__group}>
            <span>Сумма</span>
            <InputNumber
              placeholder='Тотал'
              addonAfter={<MoneyCollectOutlined />}
              style={inputStyle}
              value={appData.total}
              onChange={(val) => updateApplication({ ...appData, total: val || 0 })}
              parser={numParser}
              readOnly={viewOnly}
            />
          </div>

          <div className={css.AppItem__group}>
            <span>Расходники</span>
            <InputNumber
              placeholder='Расходники'
              addonAfter={<MedicineBoxOutlined />}
              style={inputStyle}
              value={appData.med}
              onChange={(val) => updateApplication({ ...appData, med: val || 0 })}
              parser={numParser}
              readOnly={viewOnly}
            />
          </div>

          <div className={css.AppItem__group}>
            <span>Лаба</span>
            <InputNumber
              placeholder='Лаба'
              addonAfter={<ExperimentOutlined />}
              style={inputStyle}
              value={appData.lab}
              onChange={(val) => updateApplication({ ...appData, lab: val || 0 })}
              parser={numParser}
              readOnly={viewOnly}
            />
          </div>
          <div className={css.AppItem__group}>
            <span>Чистый</span>
            <InputNumber
              placeholder='Чистый'
              addonAfter={<DollarOutlined />}
              style={inputStyle}
              readOnly
              value={Math.round((appData.total - appData.med - appData.lab) * 100) / 100}
              parser={numParser}
            />
          </div>
          <Tooltip
            title={appData.executors.length === doctors.length ? 'Сотрудники закончились(' : 'Добавить сотрудника'}
            placement='left'
          >
            <Button
              onClick={() => { createExecutor(appData); }}
              icon={<UserAddOutlined />}
              type='primary'
              disabled={appData.executors.length === doctors.length || viewOnly}
            />
          </Tooltip>
        </div>
        )}
      actions={[
        (
          <Tooltip title="Удалить карточку" key="delete" placement='right'>
            <Popconfirm title="Подтвердить удаление?" onConfirm={() => { deleteApplication(appData); }}>
              <Button danger icon={<DeleteOutlined />} size='small' disabled={viewOnly} />
            </Popconfirm>
          </Tooltip>
        ),
        `Расходы: ${+appData.lab + +appData.med}`,
        `ЗП: ${usersProfit}`,
        `Доход: ${clinicProfit}`,
      ]}
    >
      <div className={css.AppItem__doctors}>
        {appData.executors.map((item) => <DoctorItem key={item.id} appData={appData} executorData={item} viewOnly={viewOnly} />)}
      </div>
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({ doctors: state.doctors.filter((doc) => !doc.deleted) });

export default connect(mapStateToProps, { updateApplication, createExecutor, deleteApplication })(AppItem);
