import { FC, useEffect, useState } from 'react';
import { Drawer, Card, Table, Typography } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import AppItem from 'components/AppItem';

import { StatisticsDay } from 'types/statistics';
import { ColumnsType } from 'antd/es/table';

import css from './StatisticsDetails.module.scss';

type IStatisticsDetails = {
  dayInfo: StatisticsDay | null;
  setDayInfo: (info: null) => void;
};

type IUsersProfitTableData = {
  id: number;
  profit: number;
};

const StatisticsDetails:FC<IStatisticsDetails> = ({ dayInfo, setDayInfo }) => {
  const [isOpen, setIsOpen] = useState(!!dayInfo);

  useEffect(() => {
    if (dayInfo) setIsOpen(true);
  }, [dayInfo]);

  if (dayInfo === null) return null;

  const columns:ColumnsType<IUsersProfitTableData> = [
    {
      title: 'Имя',
      key: 'name',
      render: (__, record) => (
        dayInfo?.doctors.find((doc) => doc.id === record.id)?.name || 'Сотрудник не найден :('
      )
    },
    {
      title: 'Итог',
      key: 'profit',
      render: (__, record) => (
        record.profit
      )
    }
  ];

  return (
    <Drawer
      title={`Детализация за ${dayjs(dayInfo.date).format('DD.MM.YYYY')}`}
      placement="right"
      closable
      open={isOpen}
      onClose={() => setIsOpen(false)}
      afterOpenChange={(open) => {
        if (!open) {
          setDayInfo(null);
        }
      }}
      className={css.StatisticsDetails}
      width="fit-content"
    >
      <div className={css.StatisticsDetails__content}>
        <Card
          className={css.StatisticsDetails__total}
          actions={[
            `Приход: ${dayInfo.total}`,
            `Расх. ${dayInfo.med}`,
            `Лаба: ${dayInfo.lab}`,
            `Прочие: ${dayInfo.common}`,
            `ЗП: ${dayInfo.toUsers}`,
            `Доход: ${dayInfo.clinicProfit}`
          ]}
          title={(
            <Typography.Title
              level={2}
              className={cn('h2Fix', css.StatisticsDetails__groupTitle)}
            >
              Итоги дня
            </Typography.Title>
          )}
        >
          <Table
            columns={columns}
            dataSource={Object.entries(dayInfo.byUsers).map((usr) => ({ id: Number(usr[0]), profit: usr[1], key: usr[0] })) || []}
            pagination={false}
          />

        </Card>
        <Typography.Title
          level={2}
          className={cn('h2Fix', css.StatisticsDetails__groupTitle)}
        >
          Акты
        </Typography.Title>

        {dayInfo.applications.map((app) => <AppItem key={app.id} appData={app} viewOnly />)}
      </div>

    </Drawer>
  );
};

export default StatisticsDetails;
