import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { store } from 'store';
import { replaceDoctors } from 'store/doctors';

import { Doctor } from 'types/doctors';

import getLocalStores from './getLocalStores';
import importValidator from './inportValidator';

const getLatestDoctors = () => {
  const { doctors: currentDoctors } = store.getState();

  if (currentDoctors.length) return currentDoctors;

  const archive = getLocalStores(true);

  const notToday = archive.filter((item) => !(dayjs(item.date).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')));

  if (!notToday.length) return [];

  notToday.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix());

  const latest = notToday?.[0]?.store || { doctors: [] };

  try {
    const checkedDoctors = importValidator.doctors(
      typeof latest.doctors === 'string' ? JSON.parse(latest.doctors) : latest.doctors
    ) as Doctor[];

    if (checkedDoctors.length) {
      toast.success('Список сотрудников успешно восстановлен!');

      return checkedDoctors;
    }
  } catch (error) {
    toast.warning('Произошла ошибка при попытке восстановления списка сотрудников');

    return [];
  }

  return [];
};

const restoreLatestDoctors = () => {
  const doctors = getLatestDoctors();
  store.dispatch(replaceDoctors(doctors));
};

export default restoreLatestDoctors;
