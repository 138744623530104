import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { Button, Card, InputNumber, Popover, Table, Upload, UploadProps } from 'antd';
import { CloudDownloadOutlined, CloudUploadOutlined, CoffeeOutlined } from '@ant-design/icons';

import importValidator from 'services/inportValidator';
import download from 'services/download';
import numParser from 'services/numParser';
import getLocalStores from 'services/getLocalStores';

import { setCommon } from 'store/common';
import { replaceApplications } from 'store/applications';
import { importDoctor, updateDoctor } from 'store/doctors';

import { RootState, store } from 'store';

import { ColumnsType } from 'antd/es/table';
import { StatisticsDay } from 'types/statistics';
import { App } from 'types/applications';
import { Doctor } from 'types/doctors';

import calcDailyData from 'services/calcDailyData';
import css from './AppTotal.module.scss';

type IAppTotal = {
  applications: App[];
  doctors: Doctor[];
  common: number;
  setCommon: (current: number) => void;
  replaceApplications: (apps: App[]) => void;
  updateDoctor: (doc: Doctor) => void;
  importDoctor: (doc: Doctor) => void;
};

type IUsersProfitTableData = {
  key:number,
  id:number,
  name: string,
  profit: number,
};

const AppTotal: FC<IAppTotal> = (
  { applications, doctors, common, setCommon, replaceApplications, updateDoctor, importDoctor }
) => {
  const [day, setDayData] = useState({} as Omit<StatisticsDay, 'doctors' | 'date' | 'applications'>);

  useEffect(() => {
    setDayData(calcDailyData({ store: store.getState() }));
  }, [applications, doctors, common]);

  const columns:ColumnsType<IUsersProfitTableData> = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Итог',
      dataIndex: 'profit',
      key: 'profit',
    }
  ];

  const usersProfitTableData:IUsersProfitTableData[] = [];

  Object.entries(day.byUsers || {}).forEach((entr) => {
    if (Number.isNaN(+entr[0])) return;
    usersProfitTableData.push({
      key: +entr[0],
      id: +entr[0],
      name: doctors.find((doc) => doc.id === +entr[0])?.name || 'Неизвестно :(',
      profit: entr[1],
    });
  });

  const appExportHandler = () => {
    download(
      'luckyApps',
      JSON.stringify(getLocalStores(true).filter((store) => store.date === dayjs().format('YYYY.MM.DD')))
    );
  };

  const uploadProps: UploadProps = {
    multiple: false,
    accept: '.json',
    showUploadList: false,
    customRequest: ({ file }) => {
      const reader = new FileReader();

      reader.onload = async ({ target }) => {
        const data = target?.result;
        try {
          const parsedData = JSON.parse(data as string);

          const checked = importValidator.globalImport(parsedData);

          checked.forEach((record) => {
            record.store.doctors.forEach((doc: Doctor) => {
              const realIndex = doctors.findIndex((existsDoc) => existsDoc.id === doc.id);
              if (realIndex === -1) {
                importDoctor(doc);
              } else {
                updateDoctor({ ...doctors[realIndex], deleted: false });
              }
            });

            replaceApplications(record.store.applications);
            setCommon(record.store.common);
          });

          toast.success('Импорт успешно выполнен!');
        } catch (err) {
          toast.error('Произошла ошибка при обработке файла');
        }
      };
      reader.readAsText(file as Blob);
    },
  };

  return (
    <div className={css.AppTotal}>
      <Card
        className={css.AppTotal__content}
        title={(
          <div className={css.AppTotal__header}>
            <div className={css.AppTotal__group}>
              <span>Прочие расходы: </span>
              <InputNumber
                addonAfter={<CoffeeOutlined />}
                value={day.common}
                onChange={(val) => setCommon(val || 0)}
                parser={numParser}
              />
            </div>
            <div className={css.AppTotal__group}>
              <Upload {...uploadProps}>
                <Popover
                  title="Восстановить акты из файла"
                  content="Информация на этой странице будет утеряна без возможности восстановления."
                  placement='left'
                >
                  <Button danger icon={<CloudUploadOutlined />} onClick={() => {}}>Восстановить из файла</Button>
                </Popover>
              </Upload>
              <Popover
                title='Сохранить данные в файл'
                content="Сохранить текущее состояние страницы в файл для открытия в будущем"
                placement='left'
              >
                <Button icon={<CloudDownloadOutlined />} onClick={appExportHandler}>Экспортировать</Button>
              </Popover>
            </div>
          </div>
        )}
        actions={[
          `Приход: ${day.total}`,
          `Расх. ${day.med}`,
          `Лаба: ${day.lab}`,
          `Прочие: ${day.common}`,
          `ЗП: ${day.toUsers}`,
          `Доход: ${day.clinicProfit}`
        ]}
      >
        <Table columns={columns} dataSource={usersProfitTableData} pagination={false} />
      </Card>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  applications: state.applications,
  doctors: state.doctors,
  common: state.common,
});

export default connect(mapStateToProps, { setCommon, replaceApplications, importDoctor, updateDoctor })(AppTotal);
