import { FC } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Tooltip, type MenuProps, Avatar, Switch } from 'antd';
import {
  CalculatorOutlined,
  DatabaseOutlined,
  HistoryOutlined,
  TeamOutlined,
} from '@ant-design/icons';

import { changeTheme } from 'store/theme';

import { RootState } from 'store';

import img from './dogeNew.png';

import css from './Header.module.scss';

type IThemeSwitcher = {
  changeTheme: (value: boolean) => void,
  currentTheme: string
};

const ThemeSwitcher: FC<IThemeSwitcher> = ({ changeTheme, currentTheme }) => (
  <Tooltip placement='left' title="Переключить тему">
    <Switch
      checkedChildren="Ночь"
      unCheckedChildren="День"
      onChange={changeTheme}
      checked={currentTheme === 'dark'}
    />
  </Tooltip>
);

const mapStateToProps = (store: RootState) => ({
  currentTheme: store.theme
});

const StoreThemeSwither = connect(mapStateToProps, { changeTheme })(ThemeSwitcher);

const items: MenuProps['items'] = [
  {
    key: 'avatar',
    label: (
      <Tooltip title="А ты мыл попу?!" placement='right'>
        <Avatar src={img} className={css.Header__logo} draggable={false} size="large" />
      </Tooltip>
    ),
    style: { marginRight: 'auto' }
  },
  {
    key: 'calculator',
    label: <Link to='/calculator/' style={{ color: 'unset' }}>Калькулятор</Link>,
    icon: <CalculatorOutlined />,
  },
  {
    key: 'history',
    label: <Link to='/history/' style={{ color: 'unset' }}>История</Link>,
    icon: <HistoryOutlined />, // <FundOutlined />,
  },
  {
    key: 'users',
    label: <Link to='/users/' style={{ color: 'unset' }}>Персонал</Link>,
    icon: <TeamOutlined />,
  },
  {
    key: 'data',
    label: <Link to='/data/' style={{ color: 'unset' }}>Управление данными</Link>,
    icon: <DatabaseOutlined />,
  },
  {
    key: 'themeSwitch',
    label: <StoreThemeSwither />,
    style: { marginLeft: 'auto' }
  },
];

export default items;
