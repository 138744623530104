import { Component } from 'react';

import { Button, Collapse, Popconfirm, Result, Typography } from 'antd';
import { CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';

import { store } from 'store';

import download from 'services/download';
import getLocalStores from 'services/getLocalStores';
import reloadNotifications from 'services/reloadNotifications';

import pkg from '../../../package.json';

import css from './Error.module.scss';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};
type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    const extra = (
      <div>
        <Button
          type="primary"
          onClick={() => { window.location.reload(); }}
          className={css.Error__refresh}
        >
          Обновить страницу
        </Button>
        <Collapse className={css.Error__collapse}>
          <Collapse.Panel header="Если не помогло" key="save & clear">
            <div className={css.Error__buttons}>
              <Button
                type='primary'
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  download('LuckyFail', JSON.stringify(getLocalStores(true)));
                }}
              >
                1. Сохранить данные
              </Button>
              <Button
                type='primary'
                icon={<CloudDownloadOutlined />}
                onClick={() => {
                  download('luckyDoctorsOnFail', JSON.stringify(store.getState().doctors));
                }}
              >
                2. Сохранить сотрудников
              </Button>
              <Popconfirm
                title="Это действие невозможно отменить!"
                description="Убедитесь, что вы выполнили 2 предыдущих пункта. Это очень важно!"
                onConfirm={() => {
                  localStorage.clear();

                  reloadNotifications.set('Все данные приложения успешно очищены', 'warning');
                  reloadNotifications.set('Приложение успешно перезапущено');
                  window.location.reload();
                }}
              >
                <Button
                  danger
                  icon={<DeleteOutlined />}
                >
                  3. Очистить данные и перезапустить приложение
                </Button>
              </Popconfirm>
            </div>
          </Collapse.Panel>
        </Collapse>

        <Typography style={{ marginTop: '15px' }}>{`Версия приложения: ${pkg.version}`}</Typography>
      </div>
    );

    if (hasError) {
      return (
        <Result
          status="500"
          title="Произошла ошибка"
          subTitle="Вот так бывает в жизни..."
          extra={extra}
          className={css.Error}
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
