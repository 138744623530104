import { FC, useState } from 'react';
import { connect } from 'react-redux';

import { Button, Input, InputNumber, Popconfirm, Table, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, CloseSquareOutlined, SaveOutlined } from '@ant-design/icons';

import numParser from 'services/numParser';

import { updateDoctor, deleteDoctor } from 'store/doctors';

import { RootState } from 'store';

import { ColumnsType } from 'antd/es/table';
import { Doctor } from 'types/doctors';

import css from './UserTable.module.scss';

type IUsersTable = {
  doctors: Doctor[];
  updateDoctor: (doctor: { id: number, name: string, defaultPercentage: number }) => void;
  deleteDoctor: (id: number) => void;
};

const UsersTable: FC<IUsersTable> = ({ doctors, updateDoctor, deleteDoctor }) => {
  const [currentUser, setCurrentUser] = useState({ id: 0, name: '', defaultPercentage: 0 });

  const saveUserHandler = () => {
    updateDoctor(currentUser);
    setCurrentUser({ id: 0, name: '', defaultPercentage: 0 });
  };

  const columns:ColumnsType<Doctor> = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
      render: (__, record) => (record.id === currentUser.id ? (<Input
        value={currentUser.name}
        onChange={(val) => setCurrentUser((usr) => ({ ...usr, name: val.target.value || '' }))}
      />) : record.name),
    },
    {
      title: 'Ставка по умолч.',
      dataIndex: 'defaultPercentage',
      key: 'defaultPercentage',
      render: (__, record) => (record.id === currentUser.id ?
        (<InputNumber
          value={currentUser.defaultPercentage}
          onChange={(val) => setCurrentUser((usr) => ({ ...usr, defaultPercentage: val || 0 }))}
          parser={numParser}
        />)
        : record.defaultPercentage)
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (__, record) => (
        <div className={css.UserTable__actions}>
          {record.id === currentUser.id ?
            (
              <>
                <Tooltip title="Сохранить изменения" placement='bottom'>
                  <Button type='primary' icon={<SaveOutlined />} onClick={saveUserHandler} />
                </Tooltip>
                <Tooltip title="Отменить изменения" placement='bottom'>
                  <Popconfirm
                    title="Отменить изменения?"
                    onConfirm={
                        () => setCurrentUser({ id: 0, name: '', defaultPercentage: 0 })
                    }
                  >
                    <Button type="primary" danger icon={<CloseSquareOutlined />} />
                  </Popconfirm>
                </Tooltip>

              </>
            ) :
            (
              <>
                <Tooltip title="Редактировать сотрудника" placement="bottom">
                  <Button
                    icon={<EditOutlined />}
                    onClick={
                        () => setCurrentUser({ id: record.id, name: record.name, defaultPercentage: record.defaultPercentage })
                    }
                  />
                </Tooltip>
                <Tooltip title="Удалить сотрудника" placement='bottom'>
                  <Popconfirm title="Подтвердить удаление?" onConfirm={() => { deleteDoctor(record.id); }}>
                    <Button icon={<DeleteOutlined />} danger />
                  </Popconfirm>
                </Tooltip>
              </>
            ) }
        </div>
      ),
    },
  ];
  const data = doctors.map((doc) => ({ ...doc, key: doc.id })).filter((doc) => !doc.deleted);

  return <Table dataSource={data} columns={columns} pagination={false} className={css.UserTable} />;
};

const mapStateToProps = (state: RootState) => ({
  doctors: state.doctors
});

export default connect(mapStateToProps, { updateDoctor, deleteDoctor })(UsersTable);
