import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { FC, useEffect, useState } from 'react';

import { Typography } from 'antd';

import StatisticsData from 'components/StatisticsData';
import StatisticsTable from 'components/StatisticsTable';

import calcDailyData from 'services/calcDailyData';
import getLocalStores from 'services/getLocalStores';
import importValidator from 'services/inportValidator';

import { Doctor } from 'types/doctors';
import { StatisticsDay } from 'types/statistics';

import css from './Statistics.module.scss';

const Statistics: FC = () => {
  const [tableData, updateTableData] = useState([] as StatisticsDay[]);
  const [dates, setDates] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [stat, setStat] = useState({
    total: 0,
    med: 0,
    lab: 0,
    clear: 0,
    toUsers: 0,
    clinicProfit: 0,
    byUsers: {} as Record<number, number>
  });

  useEffect(() => {
    try {
      const localData = importValidator.globalImport(getLocalStores(false));

      const tableData = localData.map((day) => {
        const roundedDayData = calcDailyData(day);
        try {
          return {
            ...roundedDayData,
            // eslint-disable-next-line no-underscore-dangle
            date: dayjs.unix(JSON.parse(day.store._persist).version) || dayjs(day.date),
            doctors: day.store.doctors || [],
            applications: day.store.applications || [],
          };
        } catch (error) {
          return {
            ...roundedDayData,
            date: dayjs(day.date),
            doctors: day.store.doctors || [],
            applications: day.store.applications || [],
          };
        }
      });

      const start = dates?.[0];
      const end = dates?.[1];

      const period = tableData.filter((day) => {
        const currentDay = day.date.unix();

        if (start && end) return start.unix() <= currentDay && end.unix() >= currentDay;

        if (start) return start.unix() <= currentDay;

        if (end) return end.unix() >= currentDay;

        return true;
      });

      const periodResult = period.reduce((acc, day) => {
        Object.keys(day.byUsers).forEach((userId) => {
          const docName = day.doctors.find((doc: Doctor) => doc.id === Number(userId))?.name;
          acc.byUsers[docName] = (acc.byUsers[docName] || 0) + +day.byUsers[Number(userId)];
        });

        return {
          ...acc,
          total: +acc.total + +day.total,
          med: +acc.med + +day.med,
          lab: +acc.lab + +day.lab,
          clear: +acc.clear + +day.clear,
          toUsers: +acc.toUsers + +day.toUsers,
          clinicProfit: +acc.clinicProfit + +day.clinicProfit,
        };
      }, {
        total: 0,
        med: 0,
        lab: 0,
        clear: 0,
        toUsers: 0,
        clinicProfit: 0,
        byUsers: {} as Record<number, number>
      });

      setStat(periodResult);
      updateTableData(period);
    } catch (err) {
      toast.error('Произошла ошибка при построении таблицы, данные могут быть некорректными');
    }
  }, [dates]);

  return (
    <div className={css.Statistics}>
      <div className={css.Statistics__header}>
        <Typography.Title level={2} className='h2Fix'>История</Typography.Title>
      </div>

      <StatisticsData stat={stat} dates={dates} setDates={setDates} />

      <StatisticsTable tableData={tableData} />
    </div>
  );
};

export default Statistics;
