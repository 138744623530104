import { Dayjs } from 'dayjs';

import { RootState } from 'store';

import { App } from 'types/applications';
import { StatisticsDay } from 'types/statistics';

type IcalcDailyData = (day: { date?: Dayjs | string, key?: string, store: RootState })
=> Omit<StatisticsDay, 'date' | 'doctors' | 'applications'>;

const calcDailyData: IcalcDailyData = (day) => {
  const applications = day.store.applications || [];
  const common = day.store.common || 0;

  const byUsersMap = new Map();

  const dayData = applications.reduce((all: { [x: string]:number }, app: App) => {
    const appProfit = +app.total - app.med - app.lab;
    let usersProfit = 0;

    app.executors.forEach((exe) => {
      if (!byUsersMap.has(exe.userId)) {
        byUsersMap.set(exe.userId, 0);
      }
      const executorAppTotal = Math.round((appProfit * exe.currentPercentage)) / 100;
      byUsersMap.set(exe.userId, byUsersMap.get(exe.userId) + executorAppTotal);

      usersProfit += +executorAppTotal;
    });

    return {
      total: +all.total + +app.total,
      med: +all.med + +app.med,
      lab: +all.lab + +app.lab,
      clear: +all.clear + +app.total - app.med - app.lab,
      toUsers: +all.toUsers + +usersProfit
    };
  }, {
    total: 0,
    med: 0,
    lab: 0,
    clear: 0 - common,
    toUsers: 0,
  });

  const roundedDayData = {
    ...dayData,
    total: Math.round(dayData.total * 100) / 100,
    med: Math.round(dayData.med * 100) / 100,
    lab: Math.round(dayData.lab * 100) / 100,
    clear: Math.round(dayData.clear * 100) / 100,
    toUsers: Math.round(dayData.toUsers * 100) / 100,

    common: Math.round(common * 100) / 100,

    clinicProfit: Math.round((+dayData.clear - +dayData.toUsers) * 100) / 100,
    byUsers: Object.fromEntries(
      Array.from(byUsersMap.entries()).map((entr) => [entr[0], Math.round(entr[1] * 100) / 100])
    ),
  };

  return roundedDayData;
};

export default calcDailyData;
