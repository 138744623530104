import dayjs from 'dayjs';

const importValidator = {
  applications: (check: unknown) => {
    if (!(typeof check === 'object' && Array.isArray(check))) throw new Error('Некорректный тип данных' + JSON.stringify(check));

    return check.map((app) => {
      if (typeof app !== 'object') throw new Error('Некорректный тип элементов');

      if (!(Object.hasOwn(app, 'id') && !Number.isNaN(app.id) && app.id !== 0)) throw new Error('id');
      if (!(Object.hasOwn(app, 'total') && !Number.isNaN(app.total))) throw new Error('total');
      if (!(Object.hasOwn(app, 'med') && !Number.isNaN(app.med))) throw new Error('med');
      if (!(Object.hasOwn(app, 'lab') && !Number.isNaN(app.lab))) throw new Error('lab');

      if (!(Object.hasOwn(app, 'executors') && typeof app.executors === 'object' && Array.isArray(app.executors))) {
        throw new Error('executors');
      }

      const executors = importValidator.executors(app.executors);

      return { id: app.id, total: app.total, med: app.med, lab: app.lab, executors };
    });
  },

  executors: (check: unknown) => {
    if (!(typeof check === 'object' && Array.isArray(check))) throw new Error('Некорректный тип данных');

    return check.map((exec) => {
      if (typeof exec !== 'object') throw new Error('Некорректный тип элементов');

      if (!(Object.hasOwn(exec, 'id') && !Number.isNaN(exec.id) && exec.id !== 0)) throw new Error('id');
      if (!(Object.hasOwn(exec, 'userId') && !Number.isNaN(exec.userId) && exec.userId !== 0)) throw new Error('userId');
      if (!(Object.hasOwn(exec, 'currentPercentage') && !Number.isNaN(exec.currentPercentage))) throw new Error('currPerc');

      return { id: exec.id, userId: exec.userId, currentPercentage: exec.currentPercentage };
    });
  },

  doctors: (check: unknown) => {
    if (!(typeof check === 'object' && Array.isArray(check))) throw new Error('Некорректный тип данных');

    return check.map((item) => {
      if (typeof item !== 'object') throw new Error('Некорректный тип элементов');
      if (!(Object.hasOwn(item, 'id') && !Number.isNaN(item.id) && item.id !== 0)) throw new Error('id');
      if (!(Object.hasOwn(item, 'name') && item.name !== '')) throw new Error('name');
      if (!(Object.hasOwn(item, 'defaultPercentage') && !Number.isNaN(item.defaultPercentage))) throw new Error('defPerc');
      if (!(Object.hasOwn(item, 'deleted') && typeof item.deleted === 'boolean')) throw new Error('deleted');

      return { id: item.id, name: item.name, defaultPercentage: item.defaultPercentage, deleted: item.deleted };
    });
  },

  common: (check: unknown) => {
    if (!(typeof check === 'number' || !Number.isNaN(Number(check)))) throw new Error('Некорректный тип данных');

    return Number(check);
  },

  globalImport: (check: unknown) => {
    if (!(typeof check === 'object' && Array.isArray(check))) throw new Error('Некорректный тип данных');

    return check.map((record) => {
      if (typeof record !== 'object') throw new Error('Некорректный тип элементов');
      if (!(Object.hasOwn(record, 'date') && dayjs(record.date))) throw new Error('date');
      if (!(Object.hasOwn(record, 'store') && typeof record.store === 'object')) throw new Error('store');
      if (!(Object.hasOwn(record, 'key') && record.key && record.key.includes('m1w_dev') && record.key.includes('luckycalc'))) {
        throw new Error('key');
      }

      const { store } = record;

      if (!(Object.hasOwn(store, 'applications'))) throw new Error('applications not in store');
      if (!(Object.hasOwn(store, 'doctors'))) throw new Error('doctors not in store');
      if (!(Object.hasOwn(store, 'common'))) throw new Error('common not in store');

      const { applications: checkApp, doctors: checkDoc, common: checkCommon, ...rest } = store;

      const applications = importValidator.applications(typeof checkApp === 'string' ? JSON.parse(checkApp) : checkApp);
      const doctors = importValidator.doctors(typeof checkDoc === 'string' ? JSON.parse(checkDoc) : checkDoc);
      const common = importValidator.common(typeof checkCommon === 'string' ? JSON.parse(checkCommon) : checkCommon);

      return { date: record.date, key: record.key, store: { applications, doctors, common, ...rest } };
    });
  },
};

export default importValidator;
