import { createSlice } from '@reduxjs/toolkit';

import { App } from 'types/applications';

const initialState: App[] = [];
const defaultApp = {
  total: 0,
  med: 0,
  lab: 0,
  executors: [],
};
const defaultExecutor = {
  userId: null,
  currentPercentage: 0,
};

const applications = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    createApplication: (store) => [{ ...defaultApp, id: store.length + 1 }, ...store],
    updateApplication: (store, action) => {
      const index = store.findIndex((item) => action.payload.id === item.id);

      return [...store.slice(0, index), action.payload, ...store.slice(index + 1)];
    },
    deleteApplication: (store, action) => {
      const index = store.findIndex((item) => item.id === action.payload.id);

      return [...store.slice(0, index), ...store.slice(index + 1)];
    },
    clearApplications: () => [],
    replaceApplications: (__, { payload }) => payload,
    createExecutor: (store, action) => {
      const appData = action.payload;
      const appIndex = store.findIndex((item) => item.id === appData.id);

      return [
        ...store.slice(0, appIndex),
        { ...store[appIndex],
          executors: [
            ...store[appIndex].executors, { ...defaultExecutor, id: store[appIndex].executors.length + 1 }
          ]
        },
        ...store.slice(appIndex + 1)];
    },
    updateExecutor: (store, action) => {
      const { app, executor } = action.payload;
      const appIndex = store.findIndex((item) => app.id === item.id);
      const { executors } = store[appIndex];
      const executorIndex = executors.findIndex((item) => item.id === executor.id);

      const newExecutors = [...executors.slice(0, executorIndex), executor, ...executors.slice(executorIndex + 1)];

      return [...store.slice(0, appIndex), { ...store[appIndex], executors: newExecutors }, ...store.slice(appIndex + 1)];
    },
    deleteExecutor: (store, action) => {
      const { app, executor } = action.payload;
      const appIndex = store.findIndex((item) => app.id === item.id);
      const { executors } = store[appIndex];
      const executorIndex = executors.findIndex((item) => item.id === executor.id);

      const newExecutors = [...executors.slice(0, executorIndex), ...executors.slice(executorIndex + 1)];

      return [...store.slice(0, appIndex), { ...store[appIndex], executors: newExecutors }, ...store.slice(appIndex + 1)];
    },

  },
});

export const {
  createApplication,
  updateApplication,
  deleteApplication,
  clearApplications,
  replaceApplications
} = applications.actions;
export const { createExecutor, updateExecutor, deleteExecutor } = applications.actions;

export default applications.reducer;
